export function intlNumberFormat(
  locale: string | undefined,
  currency: string | undefined,
  value: number
) {
  try {


    return new Intl.NumberFormat(locale, {
      style: "currency",
      currency,
    }).format(value);
  } catch (e) {
    return value;
  }
}

export const getCurrencySymbol = (locale: string, currency: string) =>
  (0)
    .toLocaleString(locale, {
      style: "currency",
      currency,
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    })
    .replace(/\d/g, "")
    .trim();

export function intlNumberFormatWithoutSymbol(
  locale: string,
  currency: string,
  value: number
) {
  try {
    return new Intl.NumberFormat(locale, {
      style: "currency",
      currency,
    })
      .format(value)
      .replace(getCurrencySymbol(locale, currency), "")
      .trim();
  } catch (e) {
    return value;
  }
}
