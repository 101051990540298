import { SxProps, TableCell, TableSortLabel, Typography } from "@mui/material";
import { ArrowTriIcon } from "components/SvgIcons/ArrowTriIcon";
import React from "react";

interface SortLabelCellProps {
  label?: string;
  active?: boolean;
  direction?: "asc" | "desc";
  onClick?: () => void;
  sx?: SxProps;
}

const SortLabelCell: React.FC<SortLabelCellProps> = ({
  label,
  active,
  onClick,
  sx,
}) => {
  return (
    <TableCell
      sx={{
        fontSize: "0.875rem",
        boxShadow: "none",
        color: "colors.gray.o70",
        fontWeight: 700,
        backgroundColor: "inherit",
        ...sx,
      }}
    >
      <TableSortLabel
        active={active}
        direction={"desc"}
        onClick={onClick}
        {...(active && { IconComponent: ArrowTriIcon })}
      >
        <Typography fontWeight={700} variant="inter14">
          {label}
        </Typography>
      </TableSortLabel>
    </TableCell>
  );
};

export default SortLabelCell;
