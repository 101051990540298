import { Alert, AlertProps, Typography } from "@mui/material";
import { FC } from "react";

interface Props extends AlertProps {
  title?: string;
  message?: string;
}

const SnackbarAlert: FC<Props> = ({ title, message, ...rest }) => (
  <Alert
    sx={{
      minWidth: "23rem",
      "& .MuiAlert-message": {
        width: "100%",
      },
    }}
    {...rest}
  >
    <Typography variant="inter14">
      {title && <b>{title}</b>} {message}
    </Typography>
  </Alert>
);

export default SnackbarAlert;
