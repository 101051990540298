import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

export const PrinterIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon
      width="24"
      height="24"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
        <path
          d="M5.714 3v4.714H3.357A2.366 2.366 0 0 0 1 10.071v6.286a2.366 2.366 0 0 0 2.357 2.357h2.357v3.143h12.572v-3.143h2.357A2.366 2.366 0 0 0 23 16.357v-6.286a2.366 2.366 0 0 0-2.357-2.357h-2.357V3H5.714zm1.572 1.571h9.428v3.143H7.286V4.571zM3.357 9.286h17.286c.442 0 .786.343.786.785v6.286a.776.776 0 0 1-.786.786h-2.357V14H5.714v3.143H3.357a.776.776 0 0 1-.786-.786v-6.286c0-.442.344-.785.786-.785zm1.572 1.571a.783.783 0 0 0-.786.786c0 .436.35.786.786.786.435 0 .785-.35.785-.786a.783.783 0 0 0-.785-.786zm2.357 4.714h9.428v4.715H7.286V15.57z"
          fillRule="nonzero"
        />
    </SvgIcon>
  );
};

export default PrinterIcon;
