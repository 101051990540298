import { ReservationsEndpoint } from "@constants/ApiEndpoints";
import { AxiosInstance } from "axios";
import { DepartureListModel } from "models/FrontDesk/DepartureModel";
import { FrontDeskFetchingRequestParamsModels } from "models/Shared/FetchingRequestParamsModels";
import { sprintf } from "sprintf-js";

export const getAll =
  (axios: AxiosInstance) =>
    async (
      params?: FrontDeskFetchingRequestParamsModels
    ): Promise<DepartureListModel> => {
      return await axios
        .get(ReservationsEndpoint.RESERVATION_DEPARTURE_GET_ALL, { params })
        .then(({ data }) => data);
    };

export const exportPDF =
  (axios: AxiosInstance) =>
    async (
      params?: FrontDeskFetchingRequestParamsModels
    ): Promise<void> => {
      return await axios
        .get(`${ReservationsEndpoint.RESERVATION_DEPARTURE_REPPORT_PDF}`,
          {
            params: { timestamp: new Date().getTime(), ...params },
            responseType: 'arraybuffer',
            headers: { Accept: 'application/pdf', }
          })
        .then((response) => {
          const file = new Blob([response as any], { type: 'application/pdf' });
          const fileURL = URL.createObjectURL(file);

          // Ouvrir dans un nouvel onglet
          window.open(fileURL, '_blank');

        });
    };

export const checkout =
  (axios: AxiosInstance) =>
    async (data: {
      itemsIds: number[];
      departure?: string;
    }): Promise<DepartureListModel> => {
      return await axios
        .patch(sprintf(ReservationsEndpoint.RESERVATION_ARRIVAL_CHECKOUT), {
          room_reservation_ids: data?.itemsIds,
        })
        .then(({ data }) => data);
    };
