import { AxiosInstance } from "axios";
import { ReservationDepartureService } from "services/Reservations";

export interface DepartureService {
  getAll: ReturnType<typeof ReservationDepartureService.getAll>;
  exportPDF: ReturnType<typeof ReservationDepartureService.exportPDF>;
  checkout: ReturnType<typeof ReservationDepartureService.checkout>;
}

export const initDepartureService = (
  axios: AxiosInstance
): DepartureService => ({
  getAll: ReservationDepartureService.getAll(axios),
  exportPDF: ReservationDepartureService.exportPDF(axios),
  checkout: ReservationDepartureService.checkout(axios),
});
