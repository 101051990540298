// Define the window interface extension
interface Window {
  clarity?: any;
  [key: string]: any; // Allow indexing with strings
}

declare const window: Window;

export const initClarity = (projectId: string) => {
  // Define variables used in IIFE outside the function
  let t: any;
  let y: any;

  (function (c: Window, l: Document, a: string, r: string, i: string) {
    c[a] =
      c[a] ||
      function () {
        (c[a].q = c[a].q || []).push(arguments);
      };
    t = l.createElement(r);
    t.async = true;
    t.src = "https://www.clarity.ms/tag/" + i;
    y = l.getElementsByTagName(r)[0];
    y.parentNode?.insertBefore(t, y);
    console.log("clarity init");
  })(window, document, "clarity", "script", projectId);
};
