import { ErrorMessage } from "@hookform/error-message";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  Alert,
  AlertTitle,
  Box,
  Button,
  CircularProgress,
  FormControl,
  InputLabel,
  Paper,
  Select,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { AxiosError } from "axios";
import { Error } from "components/Common/Error";
import { useRootContext } from "components/RootContainer";
import { MenuItem } from "components/styled/SelectMenuItem";
import ClassificationSwitch from "components/Switch/ClassificationSwitch";
import { get } from "lodash";
import { CashRegisterModel } from "models/Settings/Financial/CashOpertationModel";

import { FC } from "react";
import { Controller, FormProvider, useForm } from "react-hook-form";
import { useMutation } from "react-query";
import { CashierAuthFormModel } from "services/Cashier/RootCashier";
import { parseAxiosError } from "utils/parseAxiosError";
import * as yup from "yup";
import { CashierOpenMode } from "../models";

const schema = yup.object().shape({
  cash_register: yup.number().nullable().required("Cash register is required"),
  code_pin: yup
    .string()
    .typeError("Enter a valid pin code")
    .required("PIN code is required")
    .min(4, "PIN code must be at least 4 characters")
    .max(6, "PIN code must be at most 6 characters"),
});

interface CashierAuthFormProps {
  cashRegisterItems: CashRegisterModel[];
  onAuthSuccess: (params: {
    cashRegisterId: number | undefined;
    mode: CashierOpenMode;
    hasExchange: boolean;
  }) => void;
  defaultValues: CashierAuthFormModel;
  // domain: "cash_register" | "stay_card";
}

export const CashierAuthForm: FC<CashierAuthFormProps> = ({
  cashRegisterItems,
  onAuthSuccess,
  defaultValues,
  //  domain,
}) => {
  const {
    HotelApi: {
      cashier: { verifyCodePin },
    },
  } = useRootContext();

  /**
   * CASHIER MODE
   */
  // const [mode, setMode] = useState<CashierOpenMode>(CashierOpenMode.READ);

  const form = useForm<CashierAuthFormModel>({
    mode: "onChange",
    defaultValues: defaultValues ?? {
      cash_register: null,
      code_pin: null,
      mode: CashierOpenMode.READ,
    },
    resolver: yupResolver(schema),
  });

  const {
    handleSubmit,
    control,
    setValue,
    formState: { errors, isSubmitted },
    watch,
  } = form;

  const formValues = watch();
  const selectedCashRegister = cashRegisterItems.find(
    (item) => item.id === formValues.cash_register
  );
  // const queryClient = useQueryClient();

  const verifyCodePinMutation = useMutation({
    mutationKey: ["verifyCodePin"],
    mutationFn: (data: CashierAuthFormModel) => {
      return verifyCodePin({
        ...data,
        open: data.mode === CashierOpenMode.WRITE,
      });
    },
    onSuccess: (data) => {
      onAuthSuccess({
        cashRegisterId: formValues.cash_register!,
        mode: formValues.mode,
        hasExchange: data?.has_exchange,
      });
    },
    onError: (error: AxiosError, variables, context) => {
      const serverErrors = parseAxiosError<CashierAuthFormModel>(error);

      if (Object.keys(serverErrors).length > 0) {
        (
          (Object.keys(serverErrors) || []) as Array<keyof CashierAuthFormModel>
        ).map((fieldName) => {
          const errorMessage = get(serverErrors, fieldName, "") as string;
          form.setError(fieldName, {
            type: "required",
            message: errorMessage,
          });
          return null;
        });
      }
    },
  });

  const shouldShowExchangeError =
    verifyCodePinMutation?.isSuccess &&
    !verifyCodePinMutation?.data?.has_exchange &&
    isSubmitted;

  const onSubmit = (data: CashierAuthFormModel) => {
    verifyCodePinMutation.mutate({ ...data });
  };

  return (
    <Box
      sx={{
        width: "100%",
        height: "100%",
        position: "relative",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Paper
        sx={{
          width: 400,
          // height: 350,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          borderRadius: 8,
          p: 3,
        }}
      >
        <Stack direction={"column"} spacing={2} alignItems="center">
          <Typography
            align="center"
            sx={{
              fontSize: 18,
              fontWeight: 600,
              color: "colors.gray.o50",
            }}
          >
            Enter Code Pin
          </Typography>

          <Typography
            align="center"
            sx={{
              fontSize: 14,
              fontWeight: 500,
              color: "colors.gray.o50",
            }}
          >
            Please enter your code pin to access the cash register. This is
            required for authentication and security purposes.
          </Typography>

          <FormProvider {...form}>
            <Box
              component="form"
              onSubmit={handleSubmit(onSubmit)}
              display="flex"
              flexDirection="column"
              gap={2}
              sx={{ width: 300 }}
            >
              {verifyCodePinMutation.isError && (
                <Alert severity="error" sx={{}}>
                  <b>Error: </b>
                  {verifyCodePinMutation?.error?.message ??
                    "Error while connecting to cash register"}
                </Alert>
              )}

              <Controller
                name="cash_register"
                control={control}
                render={({ field }) => (
                  <FormControl variant="filled" className="background">
                    <InputLabel>Cash Register</InputLabel>
                    <Select
                      label="Cash Register"
                      fullWidth
                      variant="filled"
                      {...field}
                      onChange={(event) => {
                        field.onChange(Number(event.target.value));
                        const cashRegister = cashRegisterItems.find(
                          (item) => item.id === Number(event.target.value)
                        );
                        setValue("open", Boolean(cashRegister?.is_open));
                        setValue(
                          "mode",
                          Boolean(cashRegister?.is_open)
                            ? CashierOpenMode.WRITE
                            : CashierOpenMode.READ
                        );
                      }}
                      error={!!errors.cash_register}
                    >
                      {(cashRegisterItems || []).map((option) => (
                        <MenuItem key={option.id} value={option.id}>
                          {option.code}
                        </MenuItem>
                      ))}
                    </Select>
                    <ErrorMessage
                      errors={errors}
                      name={field.name}
                      render={({ message }) => <Error>{message}</Error>}
                    />
                  </FormControl>
                )}
              />

              <Controller
                name="code_pin"
                control={control}
                render={({ field }) => (
                  <Stack>
                    <TextField
                      label="PIN Code"
                      variant="filled"
                      className="background"
                      type="password"
                      fullWidth
                      {...field}
                      error={!!errors.code_pin}
                    />
                    <ErrorMessage
                      errors={errors}
                      name={field.name}
                      render={({ message }) => <Error>{message}</Error>}
                    />
                  </Stack>
                )}
              />
              {selectedCashRegister && !selectedCashRegister.is_open && (
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    gap: 1,
                    position: "relative",
                    width: "100%",
                    justifyContent: "flex-end",
                  }}
                >
                  <Controller
                    name="mode"
                    control={control}
                    render={({ field }) => (
                      <ClassificationSwitch
                        sx={{
                          width: "10.25rem",
                          height: "2rem",
                        }}
                        value={field.value}
                        onChange={(value: CashierOpenMode) => {
                          // setMode(value);
                          field.onChange(value);
                        }}
                        options={[
                          {
                            label: "CONSULT",
                            value: CashierOpenMode.READ,
                          },
                          {
                            label: "OPEN",
                            value: CashierOpenMode.WRITE,
                          },
                        ]}
                        switchThumbColors={{
                          [CashierOpenMode.READ]: {
                            bgColor: "#b7beca",
                            thumbColor: "#6e47ed",
                          },
                          [CashierOpenMode.WRITE]: {
                            bgColor: "#b7beca",
                            thumbColor: "#2bbf12",
                          },
                        }}
                      />
                    )}
                  />
                </Box>
              )}
              <Button
                type="submit"
                variant="contained"
                color="primary"
                disabled={verifyCodePinMutation.isLoading}
                startIcon={
                  verifyCodePinMutation.isLoading ? (
                    <CircularProgress size={20} />
                  ) : null
                }
              >
                Submit
              </Button>
              {shouldShowExchangeError && (
                <Alert severity="error">
                  <AlertTitle>Cashier Exchange</AlertTitle>
                  <Typography variant="inter12">
                    This cash register does not support exchange operations.
                    Please contact the admin.
                  </Typography>
                </Alert>
              )}
            </Box>
          </FormProvider>
        </Stack>
      </Paper>
    </Box>
  );
};
