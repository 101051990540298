import { ReservationsEndpoint } from "@constants/ApiEndpoints";
import { AxiosInstance } from "axios";
import { FrontDeskArrivalRoomModel } from "components/RoomSelectorDialogDialog/models";
import { GuestModel } from "models/Reservations/Guest/GuestModel";
import { ReservationGeneralInformationsModel } from "pages/ReservationModule/pages/CreateReservationPageNew/models";
import { sprintf } from "sprintf-js";

export const create =
  (axios: AxiosInstance) =>
  async (
    data: ReservationGeneralInformationsModel
  ): Promise<ReservationGeneralInformationsModel> => {
    return await axios
      .post(ReservationsEndpoint.RESERVATION_CREATE_GENERAL_INFORMATION, data)
      .then(({ data }) => data);
  };

export const getOne =
  (axios: AxiosInstance) =>
  async (id: number): Promise<ReservationGeneralInformationsModel> => {
    return await axios
      .get(
        sprintf(ReservationsEndpoint.RESERVATION_GET_GENERAL_INFORMATION, id)
      )
      .then(({ data }) => data);
  };

export const update =
  (axios: AxiosInstance) =>
  async (item: {
    id: number;
    data: ReservationGeneralInformationsModel;
  }): Promise<
    ReservationGeneralInformationsModel & {
      is_draft: boolean;
      draft: { general_informations: ReservationGeneralInformationsModel };
    }
  > => {
    return await axios
      .put(
        sprintf(
          ReservationsEndpoint.RESERVATION_UPDATE_GENERAL_INFORMATION,
          item.id
        ),
        item.data
      )
      .then(({ data }) => data);
  };

export const updateGuest =
  (axios: AxiosInstance) =>
  async (item: {
    id: number;
    data: { is_draft: boolean; guest_id: number };
  }): Promise<GuestModel> => {
    return await axios
      .patch(
        sprintf(ReservationsEndpoint.RESERVATION_UPDATE_GUEST, item.id),
        item.data
      )
      .then(({ data }) => data);
  };

export const getAvailableRoomsSearch =
  (axios: AxiosInstance) =>
  async (params: RoomParamsModel): Promise<FrontDeskArrivalRoomModel[]> => {
    return await axios
      .get(
        sprintf(
          ReservationsEndpoint.RESERVATION_CREATE_SEARCH_ROOM,
          params?.data?.id!
        ),
        { params: { ...params?.filters } }
      )
      .then(({ data }) => data.items);
  };

interface RoomParamsModel {
  data?: {
    id: number;
    room_number?: number | string;
  };
  filters?: FiltersModel & {
    from?: string | null;
    to?: string | null;
    show_departure: boolean;
  };
}
interface FiltersModel {
  room_number: string;
  room_types: string[] | null;
  states: number[] | null;
}
