import { AxiosInstance } from "axios";
import { ReservationStayCardService } from "services/Reservations";

export interface StayCardService {
  getStayCard: ReturnType<typeof ReservationStayCardService.getStayCard>;
  updateMarket: ReturnType<typeof ReservationStayCardService.updateMarket>;
  updateAgency: ReturnType<typeof ReservationStayCardService.updateAgency>;
  checkingGuest: ReturnType<typeof ReservationStayCardService.checkingGuest>;
  updateGuestOrder: ReturnType<
    typeof ReservationStayCardService.updateGuestOrder
  >;
  updateDemographicInfo: ReturnType<
    typeof ReservationStayCardService.updateDemographicInfo
  >;
  updateTransfert: ReturnType<
    typeof ReservationStayCardService.updateTransfert
  >;
  updateAddon: ReturnType<typeof ReservationStayCardService.updateAddon>;
  updateRateAndDiscount: ReturnType<
    typeof ReservationStayCardService.updateRateAndDiscount
  >;
  updateRoomPreferences: ReturnType<
    typeof ReservationStayCardService.updateRoomPreferences
  >;
  getItemsAndPackages: ReturnType<
    typeof ReservationStayCardService.getItemsAndPackages
  >;
  getAddons: ReturnType<typeof ReservationStayCardService.getAddons>;
  createAddon: ReturnType<typeof ReservationStayCardService.createAddon>;
  // updateAddons: ReturnType<typeof ReservationStayCardService.updateAddons>;
  deleteAddon: ReturnType<typeof ReservationStayCardService.deleteAddon>;

  getCustomStayCardPerGuest: ReturnType<
    typeof ReservationStayCardService.getCustomStayCardPerGuest
  >;
  getCustomStayCardPaxType: ReturnType<
    typeof ReservationStayCardService.getCustomStayCardPaxType
  >;
  getCustomStayCardRoomType: ReturnType<
    typeof ReservationStayCardService.getCustomStayCardRoomType
  >;
  getCustomStayTypeRoom: ReturnType<
    typeof ReservationStayCardService.getCustomStayTypeRoom
  >;

  saveCustomStayTypeRoom: ReturnType<
    typeof ReservationStayCardService.saveCustomStayTypeRoom
  >;

  recalculate: ReturnType<typeof ReservationStayCardService.recalculate>;
  updateReservationState: ReturnType<
    typeof ReservationStayCardService.updateReservationState
  >;
}

export const initStayCardService = (axios: AxiosInstance): StayCardService => ({
  getStayCard: ReservationStayCardService.getStayCard(axios),
  updateMarket: ReservationStayCardService.updateMarket(axios),
  updateAgency: ReservationStayCardService.updateAgency(axios),
  checkingGuest: ReservationStayCardService.checkingGuest(axios),
  updateDemographicInfo:
    ReservationStayCardService.updateDemographicInfo(axios),
  updateTransfert: ReservationStayCardService.updateTransfert(axios),
  updateAddon: ReservationStayCardService.updateAddon(axios),
  updateRateAndDiscount:
    ReservationStayCardService.updateRateAndDiscount(axios),
  updateRoomPreferences:
    ReservationStayCardService.updateRoomPreferences(axios),
  /**
   * Addons service TAG: #to_fix
   */
  getItemsAndPackages: ReservationStayCardService.getItemsAndPackages(axios),
  getAddons: ReservationStayCardService.getAddons(axios),
  createAddon: ReservationStayCardService.createAddon(axios),
  deleteAddon: ReservationStayCardService.deleteAddon(axios),

  /**
   * Custom Reservation services
   */

  getCustomStayCardPerGuest:
    ReservationStayCardService.getCustomStayCardPerGuest(axios),
  getCustomStayCardPaxType:
    ReservationStayCardService.getCustomStayCardPaxType(axios),
  getCustomStayCardRoomType:
    ReservationStayCardService.getCustomStayCardRoomType(axios),
  updateGuestOrder: ReservationStayCardService.updateGuestOrder(axios),

  /**
   * STAY SERVICE TYPE ROOM
   */
  getCustomStayTypeRoom:
    ReservationStayCardService.getCustomStayTypeRoom(axios),
  saveCustomStayTypeRoom:
    ReservationStayCardService.saveCustomStayTypeRoom(axios),
  recalculate: ReservationStayCardService.recalculate(axios),
  updateReservationState:
    ReservationStayCardService.updateReservationState(axios),
});
