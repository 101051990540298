import { RatesEndpoint } from "@constants/ApiEndpoints";
import { AxiosInstance } from "axios";
import {
  AssignToSearchInEnum,
  AssignToSearchResponseModel,
} from "models/RevenueManagement/Card/CardModel";
import { sprintf } from "sprintf-js";
import _ from "lodash";

export const getAll =
  (axios: AxiosInstance) =>
  async (rateId: number): Promise<AssignToSearchResponseModel[]> => {
    return await axios
      .get(sprintf(RatesEndpoint.RATE_CARD_GET_ALL, rateId))
      .then(({ data }) => data.items);
  };

export const getAllByRateId =
  (axios: AxiosInstance) =>
  async (params?: {
    limit?: number;
    offset?: number;
    search?: string;
    type?: AssignToSearchInEnum | "";
    rateId: number;
  }): Promise<AssignToSearchResponseModel[]> => {
    return await axios
      .get(sprintf(RatesEndpoint.RATE_CARD_GET_ALL_ASSIGNED, params?.rateId), {
        params: _.omit(params, "rateId"),
      })
      .then(({ data }) => data.items);
  };

export const create =
  (axios: AxiosInstance) =>
  async (params: {
    assign: {
      id: number;
      type: AssignToSearchInEnum;
    }[];
    rateId: number;
  }): Promise<AssignToSearchResponseModel[]> => {
    return await axios
      .put(sprintf(RatesEndpoint.RATE_CARD_CREATE_ASSIGN, params.rateId), {
        assign: params.assign,
      })
      .then(({ data }) => data);
  };
