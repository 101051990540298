import { Paths } from "@constants/Navigation/AppPaths";
import { Crumb } from "components/TranslatableCrub";
import React, { lazy, Suspense } from "react";
import { RoutePathDefinition } from "routing/routes";
import ExchangePage from "../pages/ExchangePage";

const CashRegisterPage = lazy(() => import("../pages/CashierPage"));
const CashierRateExchangePage = lazy(
  () => import("../pages/CashierRateExchangePage")
);
const BillingPage = lazy(() => import("../pages/BillingPage"));

const CashierRoutes: Array<RoutePathDefinition> = [
  {
    path: Paths.CASH_REGISTER_PATH,
    element: (
      <Suspense fallback={<React.Fragment>...</React.Fragment>}>
        <CashRegisterPage />
      </Suspense>
    ),
    handle: {
      crumb: () => (
        <Crumb label="Cash Register" path={Paths.CASH_REGISTER_PATH} />
      ),
    },
  },
  {
    path: Paths.EXCHANGE_PATH,
    element: (
      <Suspense fallback={<>...</>}>
        <ExchangePage />
      </Suspense>
    ),
    handle: {
      crumb: () => <Crumb label="Exchange" path={Paths.EXCHANGE_PATH} />,
    },
  },
  {
    path: Paths.RATE_EXCHANGE_PATH,
    element: (
      <Suspense fallback={<>...</>}>
        <CashierRateExchangePage />
      </Suspense>
    ),
    handle: {
      crumb: () => (
        <Crumb label="Rate Exchange" path={Paths.RATE_EXCHANGE_PATH} />
      ),
    },
  },
  {
    path: Paths.BILLING_PATH,
    element: (
      <Suspense fallback={<>...</>}>
        <BillingPage />
      </Suspense>
    ),
    handle: {
      crumb: () => <Crumb label="Billing" path={Paths.BILLING_PATH} />,
    },
  },
];

export default CashierRoutes;
