import { Button, Stack } from "@mui/material";
import { PlusIcon } from "components/SvgIcons/PlusIcon";
import { FC } from "react";
import { useCashierAuthManager } from "components/CashierAuthManager";
import { CashierOpenMode } from "components/CashierAuthManager/models";

export const DataToolbar: FC<{ handleClickAdd: () => void }> = ({
  handleClickAdd,
}) => {
  const { mode } = useCashierAuthManager();

  return (
    <Stack
      direction={"row"}
      justifyContent={"space-between"}
      alignItems={"center"}
      sx={{
        backgroundColor: "common.white",
        padding: "1rem 0.969rem 1rem 1rem",
        height: "5rem",
        boxShadow: "inset 0 -1px 0 0 #d2d7de",
      }}
    >
      <Button
        color="success"
        variant="contained"
        startIcon={<PlusIcon />}
        size="large"
        onClick={() => handleClickAdd()}
        disabled={mode === CashierOpenMode.READ}
      >
        NEW OPERATION
      </Button>
      {/* {Number(balance) > 0 && (
            <Box
                sx={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                }}
            >
                <Typography variant="inter20" sx={{ color: "colors.gray.o70" }}>
                    Balance
                </Typography>
                <Typography
                    variant="inter24"
                    fontWeight="bold"
                    sx={{
                        color: "common.black",
                        width: "12rem",
                        textAlign: "right",
                    }}
                >
                    {intlNumberFormatWithoutSymbol(
                        "fr",
                        String(globalSettings?.currency?.code),
                        Number(balance)
                    )}
                </Typography>
                <Typography
                    variant="inter24"
                    sx={{
                        color: "common.black",
                        ml: 1,
                    }}
                >
                    {getCurrencySymbol("fr", String(globalSettings?.currency?.code))}
                </Typography>
                <Button
                    sx={{ ml: "3rem" }}
                    variant="contained"
                    disabled={mode === CashierOpenMode.READ}
                >
                    <ShareIcon />
                </Button>
            </Box>
        )} */}
    </Stack>
  );
};
