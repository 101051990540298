import { ErrorMessage } from "@hookform/error-message";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  Alert,
  Autocomplete,
  Box,
  Button,
  FormControl,
  InputAdornment,
  InputLabel,
  Select,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { AxiosError } from "axios";
import { ButtonAdd } from "components/ButtonAdd";
import MandatoryFormFieldLabel from "components/MandatoryFormFieldLabel";
import { useRootContext } from "components/RootContainer";
import { MenuItem } from "components/styled/SelectMenuItem";
import MinusIcon from "components/SvgIcons/MinusIcon";
import ClassificationSwitch from "components/Switch/ClassificationSwitch";
import { get } from "lodash";
import { OtherOperationModel } from "models/Cashier/OtherOperation";
import { ClientTypeEnum } from "models/Cashier/TransactionsModels";
import { ExchangeOperationFormModel } from "pages/CashierModule/pages/CashierPage/models";
import React from "react";
import {
  Controller,
  FormProvider,
  useFieldArray,
  useForm,
} from "react-hook-form";
import { useMutation, useQueries } from "react-query";
import { SearchGuestModel } from "services/Reservations/FrontDesk/InHouse";
import { useDebounce } from "use-debounce";
import {
  getCurrencySymbol,
  intlNumberFormatWithoutSymbol,
} from "utils/intlNumberFormat";
import { parseAxiosError } from "utils/parseAxiosError";
import * as yup from "yup";

const exchangeOperationSchema = yup.object().shape({
  // guest_id: yup.number().required("Guest ID is required"),
  guest_id: yup.mixed().when("clientele_type", {
    is: ClientTypeEnum.GUEST,
    then: yup.number().required("Guest ID is required"),
    otherwise: yup.string().nullable(),
  }),
  full_name: yup.string().when("clientele_type", {
    is: ClientTypeEnum.PASSENGER,
    then: yup.string().required("Name is required for passengers"),
    otherwise: yup.string().nullable(),
  }),
  room: yup.string().when("clientele_type", {
    is: ClientTypeEnum.GUEST,
    then: yup.string().required("Room number is required for guests"),
    otherwise: yup.string().optional(),
  }),
  passport_number: yup.string().nullable(),
  nationality: yup.string().nullable(),
  details: yup
    .array()
    .of(
      yup.object().shape({
        currency_id: yup.mixed().required("Currency is required"),
        amount_exchange: yup.mixed().required("Amount Exchange is required"),
        local_amount: yup.mixed().required("Local Amount is required"),
      })
    )
    .min(1, "At least one detail is required"),
});

interface AddExchangeOperationFormModelForm {
  onClose: () => void;
  cashRegister: string | number;
  refetch: () => void;
}

export const AddExchangeOperationForm: React.FC<
  AddExchangeOperationFormModelForm
> = ({ onClose, cashRegister, refetch }) => {
  const { globalSettings } = useRootContext();

  const form = useForm<ExchangeOperationFormModel>({
    resolver: yupResolver(exchangeOperationSchema),
    defaultValues: {
      guest_id: 1,
      full_name: null,
      clientele_type: ClientTypeEnum.GUEST,
      nationality_id: null,
      passport_number: null,
      details: [
        {
          amount_exchange: null,
          currency_id: null,
          local_amount: null,
        },
      ],
    },
  });

  const { control, handleSubmit, setValue, watch } = form;

  const formValues = watch();

  const calculateTotalLocalAmount = (
    data: ExchangeOperationFormModel["details"]
  ) => {
    return data?.reduce((total, item) => {
      const amount = Number(item.local_amount) || 0;
      return total + amount;
    }, 0);
  };

  const totalLocalAmount = calculateTotalLocalAmount(formValues?.details);

  const { fields, append, remove } = useFieldArray({
    control,
    name: "details",
    keyName: "fieldId",
  });

  const onSubmit = (data: ExchangeOperationFormModel) => {
    const payload = {
      ...data,
      cash_register_id: Number(cashRegister),
      room: data?.room,
      details: data?.details?.map((item) => {
        console.log({ item });

        return {
          ...item,
          currency_id: Number(item.currency_id),
          amount_exchange: item?.amount_exchange ?? 0,
        };
      }),
    };

    createExchangeOperationMutation.mutate(payload);
  };

  const {
    HotelApi: {
      cashier: {
        exchange: { createExchange },
        rateExchange: { getAll },
      },
    },
    ChainApi: {
      settings: {
        nationality: { getAll: getAllNationality },
      },
    },
  } = useRootContext();

  const room_number = form.watch("room")?.toString() ?? "";

  const [serchNationality, setSearchNationality] = React.useState("");

  const [bouncedNationalitySearch] = useDebounce(serchNationality, 500);

  const [
    { data },
    { data: nationalities, isLoading: isLoadingNationalities },
    { data: inHouseData, isLoading: isLoadingInHouseData },
  ] = useQueries([
    {
      queryKey: ["otherOperationType"],
      queryFn: () => getAll(),
    },
    {
      queryKey: ["nationalities", bouncedNationalitySearch],
      queryFn: () =>
        getAllNationality({
          search: [
            {
              column: "name",
              operator: "like",
              value: bouncedNationalitySearch,
            },
          ],
        }),
    },
    {
      queryKey: [`RESERVATIONS_ARRIVAL_GET_ALL-${room_number}`],
      queryFn: () => {
        return InHouseService.getAllGuests({
          room_number: Number(room_number),
        });
      },
      onSuccess: (data: SearchGuestModel[]) => {
        if (data?.length > 0) {
          const firstGuest = data[0];
          form.setValue("guest_id", Number(firstGuest.id));
          form.trigger(["guest_id"]);
        }
      },
      enabled: Boolean(Number(room_number)),
    },
  ]);

  console.log({ fv: form.watch() });

  const createExchangeOperationMutation = useMutation({
    mutationKey: ["createExchangeOperationFormModel"],
    mutationFn: (data: Partial<ExchangeOperationFormModel>) =>
      createExchange(data),
    onSuccess: () => {
      refetch();
      onClose();
    },
    onError: (error: AxiosError, variables, context) => {
      const serverErrors = parseAxiosError<OtherOperationModel>(error);

      if (Object.keys(serverErrors).length > 0) {
        (
          (Object.keys(serverErrors) || []) as Array<
            keyof ExchangeOperationFormModel
          >
        ).map((fieldName) => {
          const errorMessage = get(serverErrors, fieldName, "") as string;
          form.setError(fieldName, {
            type: "required",
            message: errorMessage,
          });
          return null;
        });
      }
    },
  });

  const {
    HotelApi: {
      reservations: {
        frontDesk: { inHouse: InHouseService },
      },
    },
  } = useRootContext();

  const selectedClienteleType = form.watch("clientele_type");

  return (
    <FormProvider {...form}>
      <form onSubmit={handleSubmit(onSubmit)}>
        {createExchangeOperationMutation.isError && (
          <Alert severity="error" sx={{ mb: 2 }}>
            <b>Error while saving exchange</b>{" "}
          </Alert>
        )}
        <Stack direction="column" spacing={"1.2rem"} alignItems="flex-start">
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              gap: "1rem",
              mb: 2,
            }}
          >
            <ClassificationSwitch
              sx={{
                width: "10.5rem",
                height: "2rem",
              }}
              value={selectedClienteleType}
              onChange={(value) => {
                form.setValue("clientele_type", value as ClientTypeEnum);
                if (value === ClientTypeEnum.PASSENGER) {
                  form.setValue("guest_id", null);
                }
              }}
              options={[
                {
                  label: "Guest",
                  value: String(ClientTypeEnum.GUEST),
                  disabled: false,
                },
                {
                  label: "Passanger",
                  value: String(ClientTypeEnum.PASSENGER),
                  disabled: false,
                },
              ]}
              switchThumbColors={{
                [ClientTypeEnum.GUEST as string]: {
                  bgColor: "#b7beca",
                  thumbColor: "#6e47ed",
                },
                [ClientTypeEnum.PASSENGER as string]: {
                  bgColor: "#b7beca",
                  thumbColor: "#6e47ed",
                },
              }}
            />
          </Box>

          {selectedClienteleType === ClientTypeEnum.GUEST ? (
            <Stack
              direction="row"
              alignItems="center"
              spacing="1rem"
              width={"100%"}
            >
              <Controller
                name="room"
                control={control}
                render={({ field }) => (
                  <Stack>
                    <TextField
                      {...field}
                      label="Room"
                      fullWidth
                      sx={{
                        width: "7.5rem",
                      }}
                    />
                    <ErrorMessage
                      errors={form.formState.errors}
                      name={field.name}
                      render={({ message }) => (
                        <Typography variant="inter12" color="error">
                          {message}
                        </Typography>
                      )}
                    />
                  </Stack>
                )}
              />

              {/* <Autocomplete
                id="cash_registers-autocomplete"
                options={inHouseData || []}
                loading={isLoadingInHouseData}
                inputMode="search"
                sx={{
                  width: "100%",
                }}
                getOptionLabel={(option) =>
                  `${option?.first_name}   ${option?.last_name}` || ""
                }
                onChange={(event, newValue) => {
                  form.setValue("guest_id", Number(newValue?.id));
                }}
                isOptionEqualToValue={(option, value) => option.id === value.id}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    label={
                      <MandatoryFormFieldLabel
                        primaryLabel={"Search for guest"}
                      />
                    }
                    placeholder="Select cash registers"
                  />
                )}
              /> */}
              <Controller
                name="guest_id"
                control={control}
                render={({ field }) => (
                  <Stack width={"100%"}>
                    <Autocomplete
                      id="guest-autocomplete"
                      options={inHouseData || []}
                      loading={isLoadingInHouseData}
                      inputMode="search"
                      sx={{ width: "100%" }}
                      value={
                        inHouseData?.find(
                          (guest) => guest.id === field.value
                        ) || null
                      }
                      getOptionLabel={(option) =>
                        option
                          ? `${option?.first_name} ${option?.last_name}`
                          : ""
                      }
                      onChange={(_, newValue) => {
                        field.onChange(newValue?.id || null);
                      }}
                      isOptionEqualToValue={(option, value) =>
                        option.id === value.id
                      }
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant="outlined"
                          error={!!form.formState.errors.guest_id}
                          label={
                            <MandatoryFormFieldLabel
                              primaryLabel={"Search for guest"}
                            />
                          }
                          placeholder="Select guest"
                        />
                      )}
                    />
                    <ErrorMessage
                      errors={form.formState.errors}
                      name="guest_id"
                      render={({ message }) => (
                        <Typography variant="inter12" color="error">
                          {message}
                        </Typography>
                      )}
                    />
                  </Stack>
                )}
              />
            </Stack>
          ) : (
            <Controller
              name="full_name"
              control={control}
              render={({ field }) => (
                <Stack>
                  <TextField {...field} label="Name" fullWidth />
                  <ErrorMessage
                    errors={form.formState.errors}
                    name={field.name}
                    render={({ message }) => (
                      <Typography variant="inter12" color="error">
                        {message}
                      </Typography>
                    )}
                  />
                </Stack>
              )}
            />
          )}

          <Box
            sx={{
              backgroundColor: "colors.gray.o10",
              padding: "0.5rem",
              paddingTop: "2rem",
              borderRadius: "0.5rem",
              display: "flex",
              flexDirection: "row",
              columnGap: "1rem",
              width: "38.5rem",
            }}
          >
            <Controller
              name="passport_number"
              control={control}
              render={({ field }) => (
                <Stack>
                  <TextField
                    {...field}
                    label="Passport N°"
                    className="background"
                  />
                  <ErrorMessage
                    errors={form.formState.errors}
                    name={field.name}
                    render={({ message }) => (
                      <Typography variant="inter12" color="error">
                        {message}
                      </Typography>
                    )}
                  />
                </Stack>
              )}
            />
            <Controller
              name="nationality_id"
              control={control}
              render={({ field }) => (
                <Autocomplete
                  id="nationalities-autocomplete"
                  options={nationalities || []}
                  loading={isLoadingNationalities}
                  inputMode="search"
                  sx={{
                    width: "100%",
                  }}
                  getOptionLabel={(option) => `${option?.name}`}
                  onChange={(event, newValue) => {
                    field.onChange(newValue?.id);
                  }}
                  isOptionEqualToValue={(option, value) =>
                    option.id === value.id
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      key={`${params?.id}`}
                      variant="outlined"
                      className="background"
                      onChange={(event) => {
                        setSearchNationality(event?.target.value);
                      }}
                      label="Nationality"
                      placeholder="Select nationality"
                    />
                  )}
                />
              )}
            />
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
              gap: "2rem",
              pt: 2,
            }}
          >
            {fields.map((item, index) => {
              const currencySelectedId = form.watch(
                `details.${index}.currency_id`
              );

              const selectedCurrency = data?.items?.find(
                (curr) =>
                  String(curr?.currency_id) === String(currencySelectedId)
              );

              console.log({ selectedCurrency });

              return (
                <Stack
                  key={item.fieldId}
                  direction="row"
                  spacing={1.2}
                  alignItems="center"
                  width="100%"
                >
                  <Button
                    variant="contained"
                    color="error"
                    sx={{
                      height: "1.5rem",
                      width: "1.5rem",
                    }}
                    onClick={() => remove(index)}
                    disabled={fields?.length === 1}
                  >
                    <MinusIcon />
                  </Button>
                  <Box>
                    <FormControl
                      sx={{
                        width: "6.5rem",
                      }}
                    >
                      <InputLabel>Currency</InputLabel>
                      <Controller
                        name={`details.${index}.currency_id`}
                        control={control}
                        render={({ field }) => (
                          <Stack>
                            <Select {...field} label="Currency">
                              {(data?.items || []).map((currency, index) => (
                                <MenuItem
                                  value={currency?.currency_id!}
                                  key={index}
                                >
                                  {currency?.currency?.name}
                                </MenuItem>
                              ))}
                            </Select>
                            <ErrorMessage
                              errors={form.formState.errors}
                              name={field.name}
                              render={({ message }) => (
                                <Typography variant="inter12" color="error">
                                  {message}
                                </Typography>
                              )}
                            />
                          </Stack>
                        )}
                      />
                    </FormControl>
                    <Box
                      sx={{
                        ml: 1,
                        display: "inline-block",
                        width: "9rem",
                      }}
                    >
                      {selectedCurrency && (
                        <>
                          <Typography
                            sx={{ color: "colors.gray.o70" }}
                            variant="inter16"
                            fontWeight={500}
                            display="block"
                          >
                            Rate : {selectedCurrency?.rate}
                          </Typography>
                          <Typography
                            sx={{ color: "colors.gray.o70" }}
                            variant="inter16"
                            fontWeight={500}
                          >
                            {selectedCurrency?.currency?.name}
                          </Typography>
                        </>
                      )}
                    </Box>
                  </Box>
                  <Controller
                    name={`details.${index}.amount_exchange`}
                    control={control}
                    render={({ field }) => (
                      <Stack>
                        <TextField
                          {...field}
                          onChange={(event) => {
                            field.onChange(event);
                            setValue(
                              `details.${index}.local_amount` as "details.0.local_amount",
                              Number(event.target.value) *
                                Number(selectedCurrency?.rate)
                            );
                          }}
                          label="Amount Exchange"
                          type="number"
                          sx={{
                            width: "9.5rem",
                          }}
                        />
                        <ErrorMessage
                          errors={form.formState.errors}
                          name={field.name}
                          render={({ message }) => (
                            <Typography variant="inter12" color="error">
                              {message}
                            </Typography>
                          )}
                        />
                      </Stack>
                    )}
                  />

                  <Controller
                    name={`details.${index}.local_amount`}
                    control={control}
                    render={({ field }) => (
                      <Stack>
                        <TextField
                          value={field.value}
                          onChange={(e) => e.preventDefault()}
                          label="Amount"
                          type="number"
                          sx={{
                            width: "8.8rem",
                          }}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <Typography
                                  sx={{
                                    color: "common.black",
                                  }}
                                >
                                  {getCurrencySymbol(
                                    "fr",
                                    String(globalSettings?.currency?.code)
                                  )}
                                </Typography>
                              </InputAdornment>
                            ),
                          }}
                        />
                        <ErrorMessage
                          errors={form.formState.errors}
                          name={field.name}
                          render={({ message }) => (
                            <Typography variant="inter12" color="error">
                              {message}
                            </Typography>
                          )}
                        />
                      </Stack>
                    )}
                  />
                </Stack>
              );
            })}
          </Box>

          <ButtonAdd
            onClick={() =>
              append({
                amount_exchange: null,
                local_amount: null,
                currency_id: null,
              })
            }
            sx={{
              borderRadius: "0.5rem",
              height: "3rem",
              width: "35.5rem",
              alignSelf: "flex-end",
            }}
          >
            Add New Line
          </ButtonAdd>
          <Stack
            direction="row"
            justifyContent={"flex-end"}
            width="100%"
            spacing={5}
          >
            <Typography
              variant="inter18"
              sx={{
                color: "colors.gray.o70",
              }}
            >
              Total Amount
            </Typography>
            <Box
              gap={1}
              display={"flex"}
              flexDirection={"row"}
              alignItems="center"
            >
              <Typography variant="inter18" fontWeight="bold">
                {intlNumberFormatWithoutSymbol(
                  "fr",
                  String(globalSettings?.currency?.code),
                  Number(totalLocalAmount)
                )}
              </Typography>
              <Typography variant="inter16" fontWeight={500}>
                {getCurrencySymbol(
                  "fr",
                  String(globalSettings?.currency?.code)
                )}
              </Typography>
            </Box>
          </Stack>
          <Stack
            direction="row"
            justifyContent="space-between"
            width={"100%"}
            pt="2rem"
          >
            <Button variant="text" size="large" onClick={onClose}>
              Cancel
            </Button>
            <Button
              type="submit"
              variant="contained"
              size="large"
              sx={{
                width: "6.5rem",
              }}
              disabled={createExchangeOperationMutation.isLoading}
            >
              CHANGE
            </Button>
          </Stack>
        </Stack>
      </form>
    </FormProvider>
  );
};
