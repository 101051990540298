import { Box, Button, Paper, Stack, Typography } from "@mui/material";
import { FC } from "react";

export const ForbidenCashierModuleContainer: FC = (): JSX.Element => {
  return (
    <Box
      sx={{
        width: "100%",
        height: "100vh",
        position: "relative",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <ForbienCashierModuleView />
    </Box>
  );
};

export const ForbienCashierModuleView: FC = () => {
  return (
    <Paper
      sx={{
        width: 400,
        height: 300,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        borderRadius: 8,
        p: 3,
      }}
    >
      <Stack direction={"column"} spacing={2}>
        <Typography
          align="center"
          sx={{
            fontSize: 18,
            fontWeight: 600,
            color: "colors.gray.o50",
          }}
        >
          Access Denied
        </Typography>

        <Typography
          align="center"
          sx={{
            fontSize: 14,
            fontWeight: 500,
            color: "colors.gray.o50",
          }}
        >
          It looks like you don't have permission to access the cashier module.
          If you believe this is a mistake, please contact support. We've saved
          all your current filters and settings.
        </Typography>

        <Button
          variant="contained"
          onClick={() => window.location.reload()}
          sx={{ mt: 3 }}
        >
          Refresh Page
        </Button>
      </Stack>
    </Paper>
  );
};
