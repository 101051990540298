import { ReservationsEndpoint } from "@constants/ApiEndpoints";
import { AxiosInstance } from "axios";
import { InHouseListModel } from "models/FrontDesk/InHouseModel";
import { FrontDeskFetchingRequestParamsModels } from "models/Shared/FetchingRequestParamsModels";

export const getAll =
  (axios: AxiosInstance) =>
    async (
      params?: FrontDeskFetchingRequestParamsModels
    ): Promise<InHouseListModel> => {
      return await axios
        .get(ReservationsEndpoint.RESERVATION_INHOUSE_GET_ALL, { params })
        .then(({ data }) => data);
    };


export const exportPDF =
  (axios: AxiosInstance) =>
    async (
      params?: FrontDeskFetchingRequestParamsModels
    ): Promise<void> => {
      return await axios
        .get(`${ReservationsEndpoint.RESERVATION_INHOUSE_REPPORT_PDF}`,
          {
            params: { timestamp: new Date().getTime(), ...params },
            responseType: 'arraybuffer',
            headers: { Accept: 'application/pdf', }
          })
        .then((response) => {
          const file = new Blob([response as any], { type: 'application/pdf' });
          const fileURL = URL.createObjectURL(file);

          // Ouvrir dans un nouvel onglet
          window.open(fileURL, '_blank');

        });
    };



export const getAllGuests =
  (axios: AxiosInstance) =>
    async (
      params?: FrontDeskFetchingRequestParamsModels & {
        room_number: number;
        only_master?: boolean;
      }
    ): Promise<SearchGuestModel[]> => {
      return await axios
        .get(
          ReservationsEndpoint.RESERVATION_INHOUSE_GET_ALL_GUESTS_PER_ROOM_NUMBER,
          { params: { ...params, only_master: params?.only_master ?? true } }
        )
        .then(({ data }) => data);
    };

export interface SearchGuestModel {
  id: number;
  first_name: string;
  last_name: string;
  arrival: string;
  departure: string;
  is_master: boolean;
  age: number;
  nationality: number | string;
  passport_number: number | string;
  country: number | string;
}
