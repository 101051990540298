import { SystemPaymentIconsEnum } from "@constants/system/SystemPaymentIcons";
import { OperationTypesModel } from "models/Settings/Financial/CashRegisterOperationTypeModel";
import { CashRegisterOperationModel } from "models/Settings/Financial/CashRegisterOpertationModel";
import {
  CurrencyChainModel,
  CurrencyModel,
} from "models/Settings/Financial/CurrencyModel";
import { PaymentTypeModel } from "models/Settings/Financial/PaymentTypeModel";
import { CashRegisterOperationType } from "pages/CashierModule/pages/CashierPage/models";

export interface TransactionDataModel {
  total_shift_receipt: number | null;
  other: OtherModel;
  exchange: ExchangeResponseModel;
}

export interface SummaryModel {
  total_receipt: number | null;
  open_balance_input: {
    [key in string]: {
      id: number | null;
      label?: string;
      date_time: string;
      submit_to: number | null;
      amount: string | number;
      local_amount: string | number;
      details?: Array<{
        amount: string | number;
        currency: CurrencyChainModel;
      }>;
    };
  };
  open_balance_submit: {
    [key in string]: {
      id: number | null;
      label?: string;
      date_time: string;
      submit_to: number | null;
      amount: string | number;
      local_amount: string | number;
      details?: Array<{
        amount: string | number;
        currency: CurrencyChainModel;
      }>;
    };
  };
  receipt: {
    [key in string]: {
      // id: number | null;
      //  label?: string;
      //  date_time: string;
      //  submit_to: number | null;
      // amount: string | number;
      // local_amount: string | number;
      total_amount: number;
      payment_type: string;
      details?: Array<{
        amount: string | number;
        currency: CurrencyChainModel;
      }>;
    };
  };
  exchange: {
    details: Array<{
      currency: {
        code: string;
        name: string;
      };
      total_amount: number;
      average_exchange_rate: number;
      total_local_amount: number;
    }>;
  };

  total_closing: {
    money: {
      chek: {
        icon: "cash";
        slug: "chek";
        name: "CHEK";
        amount: -700;
        currency: {
          code: "TND";
          decimal_places: 3;
          name: "Tunisian Dinar";
          thousand_separator: " ";
        };
      };
      cash: {
        icon: "CashMoneyIcon";
        slug: "cash";
        name: "CASH";
        amount: -3116.05;
        currency: {
          code: "TND";
          decimal_places: 3;
          name: "Tunisian Dinar";
          thousand_separator: " ";
        };
      };
      visa_card: {
        icon: "VisaCardIcon";
        slug: "visa_card";
        name: "Visa Card";
        amount: 120;
        currency: {
          code: "TND";
          decimal_places: 3;
          name: "Tunisian Dinar";
          thousand_separator: " ";
        };
      };
      bank_transfert: {
        icon: "cash";
        slug: "bank_transfert";
        name: "Bank Transfert";
        amount: -951;
        currency: {
          code: "TND";
          decimal_places: 3;
          name: "Tunisian Dinar";
          thousand_separator: " ";
        };
      };
      credit_card: {
        icon: "visa_card";
        slug: "credit_card";
        name: "CREDIT CARD";
        amount: -955;
        currency: {
          code: "TND";
          decimal_places: 3;
          name: "Tunisian Dinar";
          thousand_separator: " ";
        };
      };
    };
    currencies: {
      EUR: {
        amount: 100;
        currency: {
          code: "EUR";
          decimal_places: 2;
          name: "Euro";
          thousand_separator: " ";
        };
      };
      GBP: {
        amount: 300;
        currency: {
          code: "GBP";
          decimal_places: 2;
          name: "Pound Sterling";
          thousand_separator: " ";
        };
      };
      USD: {
        amount: 50;
        currency: {
          code: "USD";
          decimal_places: 2;
          name: "US Dollar";
          thousand_separator: " ";
        };
      };
    };
  };

  other: {
    [key in string]: {
      total: string | number;
      details: {
        [key in string]: {
          amount: number;
          icon: SystemPaymentIconsEnum;
        };
      };
    };
  };
  open_close: {
    money: Array<any>;
    exchange: Array<any>;
  };
}

export interface OtherModel extends ItemModel {}

export interface ItemModel {
  id: number;
  date_time: string;
  amount: string;
  currency: Currency;
  reference: string;
  quantity: number;
  cash_register_operation: CashRegisterOperationModel;
  cash_register_operation_type: CashRegisterOperationType;
  description: string;
  posted_by: string;
  payment_type: PaymentTypeModel;
}

export interface Currency {
  id: number;
  is_active: boolean;
  is_local: boolean;
  code: string;
  name: string;
  decimal_places: number;
  thousand_separator: string;
  unit: number;
  currency_id: number;
}

export interface CashRegisterOperation {
  id: number;
  description: string;
  cash_register_operation_type_id: number;
  default_value: string;
  sens: string;
  is_active: boolean;
}

export interface Summary {
  history: History[];
  group_by_operation_type: GroupByOperationType;
}

export enum ClientTypeEnum {
  GUEST = "guest",
  PASSENGER = "passenger",
}

export interface History {
  id: number;
  description: string;
  amount: string;
  tag: string;
  clientele_name: ClientTypeEnum;
  date_time: string;
  operation_id: number;
  operation_type: string;
}

export interface GroupByOperationType {
  guaranties: OtherOperationModel[];
  prepaid: OtherOperationModel[];
  other_operations: OtherOperationModel[];
  exchange: ExchangeResponseModel[];
}

export interface OtherOperationModel {
  id: number;
  date_time: string;
  amount: string;
  currency: CurrencyModel;
  reference: string;
  quantity: number;
  cash_register_operation: CashRegisterOperationModel;
  cash_register_operation_type: OperationTypesModel;
  description: string;
  posted_by: string;
  payment_type: PaymentTypeModel;
}

export interface ExchangeResponseModel {
  id: number;
  date_time: string;
  cash_register_id: number;
  local_amount: number;
  details: ExchangeDetailModel[];
}

export interface ExchangeDetailModel {
  local_currency: CurrencyModel;
  currency: CurrencyModel;
  local_amount: string | number;
  amount_exchange: string | number;
  rate: string | number;
}
