import { CssBaseline, ThemeProvider } from "@mui/material";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import PoliciesGuard from "components/PoliciesGuard";
import RootContainer from "components/RootContainer";
import { SnackbarProviderStyled } from "components/styled/SnackbarProviderStyled";
import ReactDOM from "react-dom/client";
import { QueryClient, QueryClientProvider } from "react-query";
import { Routing } from "routing";
import { initClarity } from "utils/clarity";
import "./i18n/config";
import reportWebVitals from "./reportWebVitals";
import theme from "./theme";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: false,
      refetchOnWindowFocus: false,
    },
  },
});

if (process.env.NODE_ENV === "production") {
  window["console"]["log"] = function () {};
}
// const CLARITY_PROJECT_ID = process.env.REACT_APP_CLARITY_PROJECT_ID ;
const CLARITY_PROJECT_ID = "pijrbdqxci";

if (CLARITY_PROJECT_ID) {
  initClarity(CLARITY_PROJECT_ID);
}

root.render(
  <ThemeProvider theme={theme}>
    <CssBaseline />
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <QueryClientProvider client={queryClient}>
        <SnackbarProviderStyled maxSnack={10}>
          <PoliciesGuard>
            <RootContainer>
              <Routing />
            </RootContainer>
          </PoliciesGuard>
        </SnackbarProviderStyled>
      </QueryClientProvider>
    </LocalizationProvider>
  </ThemeProvider>
);

reportWebVitals();
