import LoadingPage from "components/LoadingPage";
import { useRootContext } from "components/RootContainer";
import { useFeedback } from "hooks/useFeedback";
import { CashRegisterModel } from "models/Settings/Financial/CashOpertationModel";
import { createContext, FC, ReactNode, useContext, useState } from "react";
import { useMutation, useQuery } from "react-query";
import { CashierAuthForm } from "./components/CashierAuthForm";
import { ForbidenCashierModuleContainer } from "./components/ForbidenCashierModuleView";
import { CashierOpenMode } from "./models";

interface CashierAuthManagerContextModel {
  cashRegisterId: number | undefined;
  mode: CashierOpenMode;
  cashRegisters: Array<CashRegisterModel>;
  onChangeCashRegister: (cashRegister: number) => void;
  onChangeMode: (mode: CashierOpenMode) => void;
  close: () => void;
  exportPDF: (id: number, from: string | null, to: string | null) => void;
  hasExchange?: boolean;
}
export const CashierAuthManagerContext =
  createContext<CashierAuthManagerContextModel>(
    {} as CashierAuthManagerContextModel
  );
export const useCashierAuthManager = () =>
  useContext(CashierAuthManagerContext);

export const CashierAuthManagerProvider: FC<{
  children: ReactNode;
  domain: "cash_register" | "stay_card";
  exchangeOnly?: boolean;
}> = ({ children, domain, exchangeOnly = false }) => {
  const [state, setState] = useState<
    Omit<
      CashierAuthManagerContextModel,
      | "cashRegisters"
      | "onChangeCashRegister"
      | "onChangeMode"
      | "close"
      | "exportPDF"
    > & {
      isAuthentificated: boolean;
    }
  >({
    isAuthentificated: false,
    cashRegisterId: undefined,
    mode: CashierOpenMode.READ,
    hasExchange: exchangeOnly,
  });

  /**
   * GET CASHIER SERVICE PROVIDER
   **/
  const {
    HotelApi: {
      cashier: {
        getAllCashRegisters,
        closeCashRegister,
        exportPdf: exportPdfService,
      },
    },
  } = useRootContext();
  /*************************/

  const {
    data,
    isLoading,
    error: errorCashRegister,
  } = useQuery({
    queryKey: ["getAllCashRegisters"],
    queryFn: () => getAllCashRegisters(),
  });

  const { refetch: close } = useQuery({
    queryKey: ["closeCashRegister", state?.cashRegisterId],
    queryFn: () => closeCashRegister(state?.cashRegisterId!),
    enabled: false,
    onSuccess: (data) => {
      setState((state) => ({
        ...state,
        isAuthentificated: false,
        cashRegisterId: undefined,
        mode: CashierOpenMode.READ,
      }));
    },
  });
  const { onError } = useFeedback();

  const exportPDF = useMutation({
    mutationKey: ["exportPDF"],
    mutationFn: (payload: {
      id: number;
      from: string | null;
      to: string | null;
    }) => {
      return exportPdfService({
        id: payload?.id,
        // from: payload?.from,
        // to: payload?.to,
      });
    },
    onError: () => {
      onError({
        snackbar: {
          title: "Export Error",
          message:
            "An error occurred while exporting the data. Please try again later.",
        },
      });
    },
  });

  if (isLoading) {
    return <LoadingPage />;
  }
  if (errorCashRegister) {
    return <ForbidenCashierModuleContainer />;
  }

  return (
    <CashierAuthManagerContext.Provider
      value={{
        cashRegisterId: state.cashRegisterId,
        mode: state.mode,
        cashRegisters: data!,
        onChangeCashRegister: (cashRegisterId) =>
          setState((oldState) => ({
            ...oldState,
            cashRegisterId: cashRegisterId,
            isAuthentificated: false,
          })),
        onChangeMode: (mode) =>
          setState((oldState) => ({
            ...oldState,
            mode,
          })),
        close: () => close(),
        hasExchange: state?.hasExchange,
        exportPDF: (id: number, from: string | null, to: string | null) =>
          exportPDF.mutate({
            id,
            from,
            to,
          }),
      }}
    >
      {state.isAuthentificated ? (
        children
      ) : (
        <CashierAuthForm
          cashRegisterItems={data!}
          defaultValues={{
            cash_register: null,
            code_pin: null,
            mode: CashierOpenMode.READ,
            open: false,
            domain,
          }}
          onAuthSuccess={(params) =>
            setState((state) => ({
              ...state,
              isAuthentificated: exchangeOnly ? params?.hasExchange : true,
              cashRegisterId: params.cashRegisterId,
              mode: params.mode,
              hasExchange: params?.hasExchange,
            }))
          }
        />
      )}
    </CashierAuthManagerContext.Provider>
  );
};
