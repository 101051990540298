import { AxiosInstance } from "axios";
import { ReservationInHouseService } from "services/Reservations";

export interface InHouseService {
  getAll: ReturnType<typeof ReservationInHouseService.getAll>;
  exportPDF: ReturnType<typeof ReservationInHouseService.exportPDF>;
  getAllGuests: ReturnType<typeof ReservationInHouseService.getAllGuests>;
}

export const initInHouseService = (axios: AxiosInstance): InHouseService => ({
  getAll: ReservationInHouseService.getAll(axios),
  exportPDF: ReservationInHouseService.exportPDF(axios),
  getAllGuests: ReservationInHouseService.getAllGuests(axios),
});
