import { Box, Typography } from "@mui/material";
import { FC } from "react";

export const EmptyData: FC = () => {
  return (
    <Box
      sx={{
        flexGrow: 1,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        p: "2.5rem",
      }}
    >
      <Typography
        variant="inter24"
        color="colors.gray.o40"
        textAlign="center"
        fontWeight="bold"
      >
        Empty content
      </Typography>
      <Typography variant="inter18" color="colors.gray.o40" textAlign="center">
        Create Transactions to see them here
      </Typography>
    </Box>
  );
};
