import { RatesEndpoint } from "@constants/ApiEndpoints";
import { AxiosInstance } from "axios";
import { CurrencyModel } from "models/RevenueManagement/Rate/CurrencyModel";

interface RateCustomReservationSearch {
  start_date: string;
  end_date: string;
  agency_card_id?: number;
  market_code_id?: number;
  room_type_id: number;
  currency_id?: number;
  with_boards: boolean;
}

interface RateCustomReservationSearchResponse {
  id: number;
  name: string;
  currency: CurrencyModel;
  boards: {
    id: number;
    name: string;
  }[];
  room_rates: { id: string; name: string }[];
}

export const search =
  (axios: AxiosInstance) =>
  async (
    searchPayload: RateCustomReservationSearch
  ): Promise<RateCustomReservationSearchResponse[]> => {
    return await axios
      .get(RatesEndpoint.RATE_CUSTOM_RESERVATION_SEARCH, {
        params: { ...searchPayload },
      })
      .then(({ data }) => data);
  };
