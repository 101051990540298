import SnackbarAlert from "components/Alerts/SnackbarAlert";
import { FC, useCallback, useEffect } from "react";
import {
  SnackbarMessage,
  useSnackbar,
  OptionsObject as SnackbarOptions,
} from "notistack";

type MessageType = {
  title?: string;
  message: string;
};

interface Props {
  success?: MessageType;
  error?: MessageType;
  options?: SnackbarOptions;
}
type SnackbarType = {
  snackbar: MessageType;
  options?: SnackbarOptions;
};

export function useFeedback(props?: Props) {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const handleToggleSnackbar = useCallback(
    (message: SnackbarMessage, options?: SnackbarOptions) =>
      enqueueSnackbar(message, {
        anchorOrigin: { vertical: "bottom", horizontal: "right" },
        autoHideDuration: 2000,
        ...props?.options,
        ...options,
      }),
    [props?.options]
  );

  const onSuccess = useCallback(
    (snackbar?: SnackbarType) => {
      const title =
        snackbar?.snackbar.title ?? props?.success?.title ?? "Data saved";
      const message =
        snackbar?.snackbar.message ??
        props?.success?.message ??
        "successfully !";
      const key = handleToggleSnackbar(
        <SnackbarAlert
          severity="success"
          title={title}
          message={message}
          onClose={() => closeSnackbar(key)}
        />,
        snackbar?.options
      );
    },
    [props?.success]
  );

  const onError = useCallback(
    (snackbar?: SnackbarType) => {
      const title =
        snackbar?.snackbar.title ?? props?.error?.title ?? "Error detected";
      const message =
        snackbar?.snackbar.message ??
        props?.error?.message ??
        "Please correct the anomalies";

      const key = handleToggleSnackbar(
        <SnackbarAlert
          severity="error"
          title={title}
          message={message}
          onClose={() => closeSnackbar(key)}
        />,
        snackbar?.options
      );
    },
    [props?.error]
  );

  return { onSuccess, onError };
}

export const HandleSnackbarAlert: FC<{
  severity: "success" | "error";
  title: string;
  msg: string;
}> = ({ severity, title, msg }): JSX.Element => {
  const { onError, onSuccess } = useFeedback({
    [severity]: {
      title: title,
      message: msg,
    },
  });

  useEffect(() => {
    severity === "error" ? onError() : onSuccess();
  }, []);

  return <></>;
};
