import { Box, Button, SxProps } from "@mui/material";
import { useEffect, useRef, useState } from "react";

export type SwitchThumbColorsPropsModel<T extends string> = Record<
  T,
  { bgColor: string; thumbColor: string }
>;

interface ClassificationSwitchPropsModel<T extends string> {
  value: T | undefined;
  onChange: (value: T) => void;
  options: Array<{ label: string; value: T; disabled?: boolean }>;
  switchThumbColors: SwitchThumbColorsPropsModel<T>;
  sx?: SxProps;
}

interface OptionPropsModel<T> {
  item: { label: string; value: T; disabled?: boolean };
  onClick: (
    event: React.MouseEvent<HTMLButtonElement>,
    selectedValue: T
  ) => void;
  isSelected: boolean;
}

const ClassificationSwitch = <T extends string>(
  props: ClassificationSwitchPropsModel<T>
): JSX.Element => {
  const { value, onChange, options, switchThumbColors, sx } = props;

  const refContainer = useRef<HTMLDivElement | null>(null);
  const [ref, setRef] = useState<HTMLDivElement | null>(null);

  const handleClick = (
    event: React.MouseEvent<HTMLButtonElement>,
    selectedValue: T
  ) => {
    if (selectedValue !== value) {
      onChange(selectedValue);
    }
    if (switchThumbColors[selectedValue] && ref) {
      ref.style.transform = `translateX(${event.currentTarget.offsetLeft}px)`;
      ref.style.transition = "all 0.5s";
      ref.style.width = `${event.currentTarget.offsetWidth}px`;
      ref.style.backgroundColor = `${switchThumbColors[selectedValue].thumbColor}`;
      refContainer.current!.style.backgroundColor = `${switchThumbColors[selectedValue].bgColor}`;
    }
  };

  return (
    <Box
      ref={refContainer}
      sx={{
        p: "0.19rem",
        width: 255,
        height: "2rem",
        borderRadius: "6px",
        color: "#fff",
        ...(sx ? sx : {}),
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          flexWrap: "nowrap",
          alignItems: "center",
          position: "relative",
          height: "100%",
        }}
      >
        <Box
          ref={(node: HTMLDivElement) => {
            if (node !== null) {
              setRef(node);
            }
          }}
          style={{
            position: "absolute",
            top: 0,
            zIndex: 1,
            width: 40,
            height: "100%",
            borderRadius: "4px",
            ...(value
              ? {
                  boxShadow:
                    "0 1px 0 0 rgba(0, 0, 0, 0.1), inset 0 -1px 0 0 rgba(3, 4, 5, 0.1)",
                }
              : {}),
          }}
        />

        <Box
          sx={{
            position: "absolute",
            top: 0,
            left: 0,
            backgroundColor: "transparent",
            zIndex: 2,
            height: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexWrap: "nowrap",
          }}
        >
          {(options || []).map((option, index) => (
            <Option
              key={index}
              item={option}
              onClick={handleClick}
              isSelected={value === option.value}
              height={ref?.offsetHeight ?? null}
            />
          ))}
        </Box>
      </Box>
    </Box>
  );
};

const Option = <T extends unknown>(
  props: OptionPropsModel<T> & { height: number | null }
) => {
  const { item, onClick, isSelected, height } = props;
  const ref = useRef<HTMLButtonElement | null>(null);

  useEffect(() => {
    if (isSelected) {
      ref.current?.click();
    }
  }, [isSelected, onClick]);

  return (
    <Button
      disabled={Boolean(item?.disabled)}
      color="inherit"
      ref={ref}
      onClick={(e) => onClick(e, item.value)}
      sx={{
        backgroundColor: "transparent",
        "&:hover": { backgroundColor: "transparent" },
        ...(height ? { minHeight: height } : {}),
        paddingY: "0px",
      }}
    >
      {item.label}
    </Button>
  );
};

export default ClassificationSwitch;
