import {
  CASH_REGISTER_CLOSE,
  CASH_REGISTER_EXPORT,
  CASH_REGISTER_VERIFY_CODE_PIN,
  CASHIER_CREATE_EXCHANHE,
  CASHIER_CREATE_OTHER_OPERATION,
  CASHIER_CREATE_OTHER_OPERATION_UPDATE,
  CASHIER_GET_ALL,
  CASHIER_GET_EXCHANGE,
  CASHIER_GET_HISTORY,
  CASHIER_GET_OTHER,
  CASHIER_GET_SUMMARY,
} from "@constants/ApiEndpoints/CashierRootEndpoint";
import { CASHIER_ROOT_ENDPOINT } from "@constants/ApiEndpoints/RootEndpoint";
import { SystemPaymentIconsEnum } from "@constants/system/SystemPaymentIcons";
import { AxiosInstance } from "axios";
import { CashierOpenMode } from "components/CashierAuthManager/models";
import _ from "lodash";
import { CashierOpenCloseBalanceListModel } from "models/Cashier/CashierRateExchange";
import { OtherOperationModel } from "models/Cashier/OtherOperation";
import {
  ExchangeResponseModel,
  OtherModel,
  SummaryModel,
} from "models/Cashier/TransactionsModels";
import { CashRegisterModel } from "models/Settings/Financial/CashOpertationModel";
import { CurrencyModel } from "models/Settings/Financial/CurrencyModel";
import { PaymentTypeModel } from "models/Settings/Financial/PaymentTypeModel";
import {
  ExchangeOperationFormModel,
  FlowDirectionEnum,
} from "pages/CashierModule/pages/CashierPage/models";
import { sprintf } from "sprintf-js";

export type Params = {
  search?: string;
  limit?: number;
  offset?: number;
};

export interface SharedCashierResponseModel<T> {
  balance: number;
  content: T;
}

export interface OpenBalanceContentModel {
  input: {
    money: Array<MoneyModel>;
    exchange: Array<ExchangeModel>;
  };
  submit: {
    money: Array<MoneyModel>;
    exchange: Array<ExchangeModel>;
  };
}

export interface MoneyModel {
  id: number;
  date: string;
  flow_direction: FlowDirectionEnum;
  amount: number;
  details: Array<DetailModel>;
  cashier: {
    id: number;
    name: string;
  };
  submit_to: {
    id: number;
    name: string;
  };
}

export interface DetailModel {
  id: number;
  payment_type_id: number;
  amount: number;
  input_submit_money_id: number;
  payment_type: Omit<PaymentTypeModel, "id" | "name" | "slug">;
}

export interface ExchangeModel {
  id: number;
  date: string;
  flow_direction: FlowDirectionEnum;
  details: {
    amount: number;
    currency: CurrencyModel;
  }[];
  cashier: {
    id: number;
    name: string;
  };
  submit_to: {
    id: number;
    name: string;
  };
}

export interface BaseCashierListResponseModel {
  exchange?: SharedCashierResponseModel<[]>;
  other?: SharedCashierResponseModel<[]>;
  open_balance?: SharedCashierResponseModel<[]>;
  summary?: SharedCashierResponseModel<[]>;
}

export interface CashierOtherListResponseModel
  extends Omit<BaseCashierListResponseModel, "other"> {
  other: SharedCashierResponseModel<OtherModel[]>;
}

export interface CashierExchangeListResponseModel
  extends Omit<BaseCashierListResponseModel, "exchange"> {
  exchange: SharedCashierResponseModel<ExchangeResponseModel[]>;
}

export interface CashierSummaryListResponseModel
  extends Omit<BaseCashierListResponseModel, "summary"> {
  summary: SharedCashierResponseModel<SummaryModel>;
}

export interface HistoryModel {
  id: number;
  type: HistoryTypesEnum;
  action: HistoryActionEnum | null;
  date: string;
  details: Detail[];
}

export enum HistoryActionEnum {
  NEW = "new",
  EDIT = "edit",
}

enum HistoryTypesEnum {
  OPEN = "open",
  INPUT_EXCHANGE = "input_exchange",
  INPUT_CASH = "input_cash",
  CLOSE = "close",
}

interface Detail {
  amount: string | number;
  type: string;
  icon?: SystemPaymentIconsEnum;
}

export enum CashierOperationTypeEnum {
  EXCHANGE = "exchange",
  OTHER = "other",
  SUBMIT = "submit",
  INPUT = "input",
}

export interface CashierOpenCloseBalanceListResponseModel
  extends Omit<BaseCashierListResponseModel, "open_balance"> {
  open_balance: SharedCashierResponseModel<CashierOpenCloseBalanceListModel>;
}

export const getCashRegisterForCurrentUser =
  (axios: AxiosInstance) =>
  async (): Promise<{
    items: CashRegisterModel[];
    total: number;
  }> =>
    await axios.get(`${CASHIER_ROOT_ENDPOINT}`).then(({ data }) => data);

export const getAllCashRegisters =
  (axios: AxiosInstance) => async (): Promise<CashRegisterModel[]> =>
    await axios.get(`${CASHIER_ROOT_ENDPOINT}`).then(({ data }) => data);
/**
 *
 */

export const getAllExchanges =
  (axios: AxiosInstance) =>
  async (params?: {
    limit?: number;
    offset?: number;
    cash_register: number;
  }): Promise<ExchangeResponseModel[]> =>
    await axios
      .get(sprintf(CASHIER_GET_EXCHANGE, params?.cash_register), {
        params: _.omit(params, "cash_register"),
      })
      .then(({ data }) => data);

export const getOtherTransactions =
  (axios: AxiosInstance) =>
  async (params?: {
    limit?: number;
    offset?: number;
    cash_register: number;
  }): Promise<CashierOtherListResponseModel> =>
    await axios
      .get(sprintf(CASHIER_GET_OTHER, params?.cash_register), {
        params: _.omit(params, "cash_register"),
      })
      .then(({ data }) => data);

export const getHistory =
  (axios: AxiosInstance) =>
  async (params?: {
    limit?: number;
    offset?: number;
    cash_register: number;
  }): Promise<HistoryModel[]> =>
    await axios
      .get(sprintf(CASHIER_GET_HISTORY, params?.cash_register), {
        params: _.omit(params, "cash_register"),
      })
      .then(({ data }) => data);

export const getSummary =
  (axios: AxiosInstance) =>
  async (params?: {
    limit?: number;
    offset?: number;
    cash_register: number;
  }): Promise<CashierSummaryListResponseModel> =>
    await axios
      .get(sprintf(CASHIER_GET_SUMMARY, params?.cash_register), {
        params: _.omit(params, "cash_register"),
      })
      .then(({ data }) => data);
/********************** */

export const update =
  (axios: AxiosInstance) =>
  async (data: Partial<OtherOperationModel>): Promise<OtherOperationModel> => {
    return await axios
      .post(CASHIER_GET_ALL, data)
      .then(({ data }) => data?.items);
  };

export const createOther =
  (axios: AxiosInstance) =>
  async (data: Partial<OtherOperationModel>): Promise<OtherOperationModel> => {
    return await axios
      .post(sprintf(CASHIER_CREATE_OTHER_OPERATION, data.cash_register_id), {
        ..._.omit(data, "cash_register_id"),
      })
      .then(({ data }) => data?.items);
  };

export const updateOther =
  (axios: AxiosInstance) =>
  async (data: Partial<OtherOperationModel>): Promise<OtherOperationModel> => {
    return await axios
      .put(
        sprintf(
          CASHIER_CREATE_OTHER_OPERATION_UPDATE,
          data.cash_register_id,
          data?.id
        ),
        {
          ..._.omit(data, "cash_register_id"),
        }
      )
      .then(({ data }) => data?.items);
  };

export const createExchange =
  (axios: AxiosInstance) =>
  async (
    data: Partial<ExchangeOperationFormModel>
  ): Promise<ExchangeOperationFormModel> => {
    return await axios
      .post(sprintf(CASHIER_CREATE_EXCHANHE, data?.cash_register_id), {
        ..._.omit(data, "cash_register_id"),
      })
      .then(({ data }) => data?.items);
  };

export const verifyCodePin =
  (axios: AxiosInstance) =>
  async (data: CashierAuthFormModel): Promise<any> => {
    return await axios
      .post(CASH_REGISTER_VERIFY_CODE_PIN, {
        ...data,
      })
      .then(({ data }) => data);
  };

export const closeCashRegister =
  (axios: AxiosInstance) =>
  async (id: number): Promise<any> => {
    return await axios.get(sprintf(CASH_REGISTER_CLOSE, id));
  };
export const exportPdf =
  (axios: AxiosInstance) =>
  async (payload: {
    id: number;
    // from: string | null;
    // to: string | null;
  }): Promise<any> => {
    return await axios.get(
      sprintf(CASH_REGISTER_EXPORT, payload?.id)
      // {
      //   params: _.omit(payload, "id"),
      // }
    );
  };
export interface CashierAuthFormModel {
  cash_register: number | null;
  code_pin: string | null;
  domain: "cash_register" | "stay_card";
  mode: CashierOpenMode;
  open: boolean;
}

export enum CashRegisterTabs {
  OPEN_CLOSE_BALANCE = "open-close-balance",
  OTHER = "other",
  EXCHANGE = "exchange",
  SUMMARY = "summary",
}
