import { CASHIER_BILLING } from "@constants/ApiEndpoints/CashierRootEndpoint";
import { AxiosInstance } from "axios";
import CashierBillingModel from "models/Cashier/CashierBilling";

import { FetchingRequestParamsModels } from "models/Shared/FetchingRequestParamsModels";
import { sprintf } from "sprintf-js";

export type Params = {
  search?: string;
  limit?: number;
  offset?: number;
};

export const getAll =
  (axios: AxiosInstance) =>
  async (params?: FetchingRequestParamsModels): Promise<any[]> =>
    await axios
      .get(`${CASHIER_BILLING}`, {
        params,
      })
      .then(({ data }) => data);

export const update =
  (axios: AxiosInstance) =>
  async (data: Partial<CashierBillingModel>): Promise<CashierBillingModel> => {
    return await axios
      .put(`${CASHIER_BILLING}/${data?.id}`, data)
      .then(({ data }) => data?.items);
  };

export const create =
  (axios: AxiosInstance) =>
  async (data: Partial<CashierBillingModel>): Promise<CashierBillingModel> => {
    return await axios
      .post(sprintf(CASHIER_BILLING), data)
      .then(({ data }) => data?.items);
  };

export const remove =
  (axios: AxiosInstance) =>
  async (id: number): Promise<CashierBillingModel> => {
    return await axios.delete(`${CASHIER_BILLING}/${id}`);
  };

export const toggleStatus =
  (axios: AxiosInstance) =>
  async (id: number): Promise<CashierBillingModel> => {
    return await axios
      .patch(`${CASHIER_BILLING}/${id}`)
      .then(({ data }) => data);
  };
