import { FC } from "react";

import { DataTable } from "./components/DataTable";
import { CashierAuthManagerProvider } from "components/CashierAuthManager";

const ExchangePage: FC = (): JSX.Element => {
  return (
    <CashierAuthManagerProvider domain="cash_register" exchangeOnly={true}>
      <DataTable />
    </CashierAuthManagerProvider>
  );
};

export default ExchangePage;
