import {
  CASHIER_OPEN_CLOSE_CREATE,
  CASHIER_OPEN_CLOSE_GET_ALL,
} from "@constants/ApiEndpoints/CashierRootEndpoint";
import { AxiosInstance } from "axios";
import _ from "lodash";
import { FetchingRequestParamsModels } from "models/Shared/FetchingRequestParamsModels";
import {
  FlowDirectionEnum,
  InputBalanceModel,
  SubmitBalanceFormModel,
} from "pages/CashierModule/pages/CashierPage/models";
import { sprintf } from "sprintf-js";
import { OpenBalanceContentModel } from "../RootCashier";

export type Params = {
  search?: string;
  limit?: number;
  offset?: number;
};

export const getAll =
  (axios: AxiosInstance) =>
  async (
    params?: FetchingRequestParamsModels & { cash_register: number }
  ): Promise<{
    open_balance: { balance: number; content: OpenBalanceContentModel };
    other: { balance: number; content: Array<any> };
  }> =>
    await axios
      .get(sprintf(CASHIER_OPEN_CLOSE_GET_ALL, params?.cash_register), {
        params: _.omit(params, "cash_register"),
      })
      .then(({ data }) => data);

export const createInput =
  (axios: AxiosInstance) =>
  async (data: InputBalanceModel): Promise<InputBalanceModel> => {
    return await axios
      .post(
        sprintf(
          CASHIER_OPEN_CLOSE_CREATE,
          data?.cashier_register_id,
          FlowDirectionEnum.INPUT
        ),
        data
      )
      .then(({ data }) => data?.items);
  };

export const createSubmit =
  (axios: AxiosInstance) =>
  async (data: SubmitBalanceFormModel): Promise<SubmitBalanceFormModel> => {
    return await axios
      .post(
        sprintf(
          CASHIER_OPEN_CLOSE_CREATE,
          data?.cashier_register_id,
          FlowDirectionEnum.SUBMIT
        ),
        data
      )
      .then(({ data }) => data?.items);
  };
