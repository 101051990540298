import { PerPaxStayCalenderModel } from "./PerPaxStayCalenderModel";
import { PerRoomStayCalenderModel } from "./PerRoomStayCalenderModel";

export enum StayCalenderModeEnum {
  NIGHT = "room",
  GUEST = "guest",
  PAX = "pax",
}

export type StayCalenderDataModel<T extends StayCalenderModeEnum | unknown> =
  T extends StayCalenderModeEnum.GUEST
    ? PerPaxStayCalenderModel
    : PerRoomStayCalenderModel;
