import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";
import * as React from "react";

export const ArrowTriIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props}>
      <path d="M12.471 9.529c.26.26.26.682 0 .942l-4 4a.667.667 0 0 1-.942 0l-4-4a.667.667 0 0 1 .942-.942L8 13.057l3.529-3.528a.667.667 0 0 1 .88-.056l.062.056zm0-4a.667.667 0 1 1-.942.942L8 2.943 4.471 6.471a.667.667 0 0 1-.88.056L3.53 6.47a.667.667 0 0 1 0-.942l4-4c.26-.26.682-.26.942 0l4 4z" fill="#475160" fillRule="nonzero" />
    </SvgIcon>
  );
};
