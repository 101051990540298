import { FC } from "react";
import { styled } from "@mui/material/styles";
import { Button as MuiButton, ButtonProps } from "@mui/material";
import { PlusIcon } from "components/SvgIcons/PlusIcon";

const Button = styled(MuiButton)(({ theme }) => ({
  border: `dashed 1px ${theme.palette.colors.purple.o70}`,
  height: "4rem",
  marginTop: "1rem",
  fontWeight: theme.typography.fontWeightBold,
}));

export const ButtonAdd: FC<ButtonProps> = ({ children, ...rest }) => (
  <Button
    fullWidth
    size="large"
    variant="outlined"
    startIcon={<PlusIcon />}
    {...rest}
  >
    {children}
  </Button>
);
