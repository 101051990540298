import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { useRootContext } from "components/RootContainer";
import { FC, useState } from "react";
import { useQuery } from "react-query";
import { ExchangeModel } from "../models";

import { useCashierAuthManager } from "components/CashierAuthManager";
import LoadingPage from "components/LoadingPage";
import { RootDialog, RootDialogTitle } from "components/RootDialog";
import { AddExchangeOperationForm } from "../../CashierPage/components/Tabs/ExchangeTab/Form";
import DataRow from "./DataRow";
import { DataToolbar } from "./DataToolbar";
import { EmptyData } from "./EmptyContent";
import SortLabelCell from "./SortLabelCell";

export const DataTable: FC<{}> = () => {
  const { cashRegisterId } = useCashierAuthManager();
  /**
   * GET CASHIER SERVICE PROVIDER
   **/
  const {
    HotelApi: {
      cashier: { getAllExchanges },
    },
  } = useRootContext();
  /*************************/

  const [openDialog, setOpenDialog] = useState<boolean>(false);

  const [order, setOrder] = useState<"asc" | "desc">("asc");
  const [orderBy, setOrderBy] = useState<keyof ExchangeModel>("date_time");

  const handleRequestSort = (property: keyof ExchangeModel) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const { data, isLoading, refetch } = useQuery({
    queryKey: ["get-exchanges", cashRegisterId, order, orderBy],
    queryFn: () => getAllExchanges({ cash_register: Number(cashRegisterId) }),
    enabled: Boolean(cashRegisterId),
    staleTime: 60000,
    cacheTime: 5 * 60 * 1000,
  });

  console.log({ data });

  const render = () => {
    if (isLoading) return <LoadingPage />;
    if (data?.length === 0)
      return (
        <Box
          sx={{
            height: "75vh",
            width: "100%",
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <EmptyData />
        </Box>
      );

    return (
      <TableContainer>
        {data?.length === 0 ? (
          <Box
            sx={{
              flexGrow: 1,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              p: "2.5rem",
            }}
          >
            <Typography
              variant="inter24"
              color="colors.gray.o40"
              textAlign="center"
              fontWeight="bold"
            >
              Empty content
            </Typography>
            <Typography
              variant="inter18"
              color="colors.gray.o40"
              textAlign="center"
            >
              Create Transactions to see them here
            </Typography>
          </Box>
        ) : (
          <Table size="small" sx={{ border: 0, p: "1rem" }}>
            <TableHead sx={{ boxShadow: "none" }}>
              <TableRow sx={{ height: "2.5rem" }}>
                <TableCell sx={{ fontSize: "0.875rem", boxShadow: "none" }} />
                <SortLabelCell
                  label="Date/Time"
                  active={orderBy === "date_time"}
                  direction={order}
                  onClick={() => handleRequestSort("date_time")}
                />
                <SortLabelCell
                  label="Currency"
                  active={orderBy === "details"}
                  direction={order}
                  onClick={() => handleRequestSort("details")}
                />
                <SortLabelCell
                  label="Rate"
                  active={orderBy === "details"}
                  direction={order}
                  onClick={() => handleRequestSort("details")}
                />
                <SortLabelCell
                  label="Amount To Exchange"
                  active={orderBy === "details"}
                  direction={order}
                  onClick={() => handleRequestSort("details")}
                  sx={{ minWidth: "23rem" }}
                />
                <SortLabelCell
                  label="Amount"
                  active={orderBy === "details"}
                  direction={order}
                  onClick={() => handleRequestSort("details")}
                />
                <TableCell sx={{ fontSize: "0.875rem", boxShadow: "none" }} />
              </TableRow>
            </TableHead>
            <TableBody>
              {(data || []).map((row, index) => (
                <DataRow key={index} row={row} index={index} />
              ))}
            </TableBody>
          </Table>
        )}
      </TableContainer>
    );
  };

  return (
    <Paper sx={{ m: 2 }}>
      <DataToolbar handleClickAdd={() => setOpenDialog(true)} />
      {render()}
      <RootDialog
        maxWidth={"lg"}
        onClose={() => setOpenDialog(false)}
        open={openDialog}
        children={
          <Box sx={{ width: "100%" }}>
            <RootDialogTitle
              component={"div"}
              onClose={() => setOpenDialog(false)}
            >
              <Typography
                variant="inter14"
                fontWeight={"fontWeightMedium"}
                color="colors.gray.o50"
              >
                Cashier . Cash Register . Exchange
              </Typography>

              <Typography
                variant="inter20"
                fontWeight={"fontWeightSemibold"}
                color="common.black"
                sx={{ lineHeight: "normal" }}
              >
                New operation
              </Typography>
            </RootDialogTitle>
            <Box>
              <AddExchangeOperationForm
                cashRegister={cashRegisterId!}
                onClose={() => setOpenDialog(false)}
                refetch={() => refetch()}
              />
            </Box>
          </Box>
        }
      />
    </Paper>
  );
};
