import { SxProps, Typography } from "@mui/material";
import { FC } from "react";
import { useTranslation } from "react-i18next";

interface MandatoryFormFieldLabelProposModel {
  primaryLabel: string;
  secondaryLabel?: string;
  secondaryLabelSxProps?: SxProps;
}

export const MandatoryFormFieldLabel: FC<
  MandatoryFormFieldLabelProposModel
> = ({
  primaryLabel,
  secondaryLabel,
  secondaryLabelSxProps = {},
}): JSX.Element => {
  const { t } = useTranslation("common");
  return (
    <>
      {primaryLabel}
      <Typography
        component="span"
        variant="inter12"
        sx={{
          color: "colors.gray.o50",
          fontWeight: "normal",
          marginLeft: "0.75rem",
          letterSpacing: "0.51px",
          ...secondaryLabelSxProps,
        }}
      >
        {secondaryLabel ?? t("mandatory")}
      </Typography>
    </>
  );
};

export default MandatoryFormFieldLabel;
