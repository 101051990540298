import { ReservationsEndpoint } from "@constants/ApiEndpoints";
import { AxiosInstance } from "axios";
import _ from "lodash";
import { StayCardGeneralInfosModel } from "models/Reservations/StayCard/ReservationStayCardModel";
import { StayCalenderModeEnum } from "pages/FrontDeskModule/Shared/components/Dialogs/StayCalendar/models";
import { PerPaxStayCalenderModel } from "pages/FrontDeskModule/Shared/components/Dialogs/StayCalendar/models/PerPaxStayCalenderModel";
import { PerRoomStayCalenderModel } from "pages/FrontDeskModule/Shared/components/Dialogs/StayCalendar/models/PerRoomStayCalenderModel";
import { SearchAvailabilitiesModel } from "pages/FrontDeskModule/Shared/components/Dialogs/StayCalendar/models/SearchAvailabiltyModel";
import { sprintf } from "sprintf-js";

type GetParamsType = {
  details: SearchAvailabilitiesModel;
  general: {
    arrival: string;
    departure: string;
  };
  mode: StayCalenderModeEnum;
};

export const getRoomRateRoom =
  (axios: AxiosInstance) =>
  async ({ ...params }: GetParamsType): Promise<PerRoomStayCalenderModel> => {
    return await axios
      .post(
        sprintf(ReservationsEndpoint.RESERVATION_STAY_CARD_GET_ROOMRATES_ROOM),
        {
          ..._.omit(params, "index"),
        }
      )
      .then((data) => data as any);
  };

export const getRoomRateGuests =
  (axios: AxiosInstance) =>
  async ({ ...params }: GetParamsType): Promise<PerPaxStayCalenderModel> => {
    return await axios
      .post(
        sprintf(
          ReservationsEndpoint.RESERVATION_STAY_CARD_GET_ROOMRATES_GUESTS
        ),
        {
          ..._.omit(params, "index"),
        }
      )
      .then(({ data }) => data);
  };

export const saveStayCardRoomRate =
  (axios: AxiosInstance) =>
  async ({
    guest,
    room_reservation,
    data,
    ...params
  }: any): Promise<StayCardGeneralInfosModel> => {
    return await axios
      .post(
        sprintf(
          ReservationsEndpoint.RESERVATION_STAY_CARD_GET_ROOMRATES_ROOM,
          guest,
          String(room_reservation)
        ),
        { ...data }
      )
      .then(({ data }) => data);
  };
